import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, isValidAddress, Modal, Proposal } from 'common';
import AddBillingContactModal from './AddBillingContactModal';
import {
  BILL_TO_SECTION,
  PAYMENT_SETUP_SECTION,
  scrollTo,
} from '../../utils/scrolling';
import { getIsSignatureRequired } from '../../utils/workflow';

interface Props {
  closeModalCallback?: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
}

const SignedBillingPrompt: React.FC<Props> = ({
  isOpen,
  onClose,
  proposal,
  closeModalCallback,
}) => {
  const [isAddingBillingContact, setIsAddingBillingContact] =
    useState<boolean>(false);
  const signingDoc = proposal.signingDocument;
  const isSignatureRequired = getIsSignatureRequired(proposal);

  if (!signingDoc || !isSignatureRequired) {
    return null;
  }

  if (isAddingBillingContact && proposal.id) {
    return (
      <AddBillingContactModal
        isOpen={isOpen}
        onBack={() => setIsAddingBillingContact(false)}
        onClose={onClose}
        proposalId={proposal.id}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      onClose={onClose}
      header="Contract successfully signed!"
      footer={
        <div className="flex justify-end">
          <Button
            label="I cannot set up payments"
            type="secondary"
            onClick={() => setIsAddingBillingContact(true)}
            className="mr-2"
          />
          <Button
            label="Proceed to payment setup"
            onClick={() => {
              if (closeModalCallback) {
                closeModalCallback(false);
              }

              onClose();

              scrollTo(
                isValidAddress(proposal.customer?.billingAddress)
                  ? PAYMENT_SETUP_SECTION
                  : BILL_TO_SECTION
              );
            }}
          />
        </div>
      }
    >
      <div className="text-lg font-bold pb-2">
        Next: Set up payment information
      </div>
      <div className="text-sm text-slate-darkest pb-2">
        If you can set up payment information on behalf of your organization
        please proceed below. Payment information is required to complete
        checkout.
      </div>
    </Modal>
  );
};

export default SignedBillingPrompt;
