import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from './Stripe/StripeForm';
import { useStripePromise } from '../../../services/stripe';
import React, { useState } from 'react';
import { MethodProps } from './methodUtils';

const CreditCard = ({
  proposal,
  paymentSchedule,
  paymentMethod,
  isEditable,
  contract,
  showCancelButton,
  onClose,
  onSubmit,
  isChangePaymentMethodClicked,
}: MethodProps) => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const loadStripe = (stripeMe: Stripe) => {
    setStripePromise(stripeMe);
  };

  const stripeId = String(paymentMethod?.configuration?.stripeAccountId);

  useStripePromise(stripeId, loadStripe);

  return (
    <div>
      {stripePromise && paymentSchedule && (
        <Elements stripe={stripePromise}>
          <StripeForm
            proposal={proposal}
            editable={!!isEditable}
            paymentSchedule={paymentSchedule}
            paymentMethod={paymentMethod}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={onClose}
            onSubmit={onSubmit}
            isChangePaymentMethodClicked={isChangePaymentMethodClicked}
          />
        </Elements>
      )}
    </div>
  );
};

export default CreditCard;
