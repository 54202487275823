import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  Address,
  AddressForm,
  AddressRest,
  AddressVerificationConfig,
  areAddressesTheSame,
  Button,
  ButtonBar,
  BuyerUpdateCustomerRequest,
  Customer,
  FormField,
  getCountry,
  getErrorMessage,
  Input,
  isValidAddress,
  log,
  Modal,
  Proposal,
  Select,
  spreadIf,
  ToggleSwitch,
  Type,
  useFlags,
  useFormValidation,
  useTranslation,
  VerifyAddressResponse,
} from 'common';
import {
  useAcceptVerifiedAddress,
  useCountries,
  useRegion,
  useTaxExemptCodes,
  useUpdateCustomerAsBuyer,
  useVerifyAddress,
} from '../services/proposal';
import { useActiveEmail } from '../utils/utils';
import VerifyAddressModal from './VerifyAddressModal';
import { useOrgDefaults } from '../services/orgConfigs';

interface Props {
  hasSigning: boolean;
  isOpen: boolean;
  proposal: Proposal;
  setIsOpen: (value: boolean) => void;
}

interface TaxDraft {
  taxExempt?: boolean;
  taxUseCode?: any;
  tin?: string;
}

const ShippingAddressModal: React.FC<Props> = ({
  hasSigning,
  isOpen,
  proposal,
  setIsOpen,
}) => {
  const { taxyTurby, enableTaxId } = useFlags();
  const { tk } = useTranslation();
  const [shippingAddressDraft, setShippingAddressDraft] = useState<Address>(
    proposal.customer?.shippingAddress || {}
  );
  const { data: countries = [] } = useCountries();
  const { data: regions = [] } = useRegion(
    getCountry(shippingAddressDraft, countries)
  );

  const { data: orgConfig } = useOrgDefaults();

  const isCollectTaxIdEnabled = !!orgConfig?.get('taxCountryRulesConfig')
    ?.configValue;
  const addressConfig = orgConfig?.get(
    'addressVerificationConfig'
  )?.configValue;
  const isAddressVerificationEnabled =
    addressConfig && taxyTurby
      ? (addressConfig as AddressVerificationConfig).enabled
      : false;

  const { data: exemptCodes } = useTaxExemptCodes({ enabled: taxyTurby });
  const [taxDraft, setTaxDraft] = useState<TaxDraft>({
    taxUseCode: proposal.customer?.taxUseCode,
    tin: proposal.customer?.tin,
    taxExempt: proposal.customer?.taxExempt,
  });

  const [verifyResponse, setVerifyResponse] = useState<
    VerifyAddressResponse | undefined
  >(undefined);

  const { isFormValid: areAddressFieldsValid, getErrorToShow } =
    useFormValidation(
      [
        {
          fieldName: 'streetAddress',
          isRequired: true,
          humanReadableName: 'Street',
        },
        { fieldName: 'city', isRequired: true, humanReadableName: 'City' },
        {
          fieldName: 'postalCode',
          isRequired: true,
          humanReadableName: 'Zip/Postal code',
        },
        {
          fieldName: 'country',
          isRequired: true,
          humanReadableName: 'Country',
        },
      ],
      shippingAddressDraft
    );

  const [customerNameDraft, setCustomerNameDraft] = useState<string>();
  const { isFormValid: hasCustomerName, getErrorToShow: getNameError } =
    useFormValidation(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Legal company name',
        },
      ],
      { name: customerNameDraft }
    );

  const queryClient = useQueryClient();
  const activeEmail = useActiveEmail();
  const { proposalId } = useParams<{ proposalId: string }>();

  const { mutate: saveCustomer, isPending: isLoading } =
    useUpdateCustomerAsBuyer(proposalId!, activeEmail)(
      (successData: Customer) => {
        setIsOpen(false);
        log.debug('MUTATION SUCCESS', successData);
      },
      (error: unknown) => {
        log.warn(getErrorMessage(error));
      },
      queryClient
    );

  const { mutate: verifyAddress, isPending: isVerifying } = useVerifyAddress(
    proposalId!,
    activeEmail
  )(
    (verifyAddressResponse: VerifyAddressResponse) => {
      log.debug('MUTATION SUCCESS', verifyAddressResponse);
      if (verifyAddressResponse.wasSaved) {
        setVerifyResponse(undefined);
        setIsOpen(false);
        saveDraft(false);
      } else {
        setVerifyResponse(verifyAddressResponse);
      }
    },
    (error: unknown) => {
      log.warn(getErrorMessage(error));
    },
    queryClient
  );

  const saveDraft = (includeAddress = true) => {
    const { shippingAddress, billingAddress } = proposal.customer || {};
    const includeTax: boolean = taxyTurby && !!taxDraft;
    const includeShipping: boolean = includeAddress && !!shippingAddressDraft;
    const includeBilling: boolean =
      includeAddress &&
      // Default to same-as-shipping
      (!isValidAddress(billingAddress) ||
        // If same-as-shipping is "checked", we should update both
        areAddressesTheSame(billingAddress, shippingAddress));

    const request: BuyerUpdateCustomerRequest = {
      name: customerNameDraft,
      ...spreadIf(includeTax, taxDraft),
      ...spreadIf(includeShipping, { shippingAddress: shippingAddressDraft }),
      ...spreadIf(includeBilling, { billingAddress: shippingAddressDraft }),
    };

    saveCustomer(request);
  };

  const { mutate: acceptVerified } = useAcceptVerifiedAddress(
    proposalId!,
    activeEmail
  )(
    (address: Address) => {
      log.debug('MUTATION SUCCESS', address);
      setVerifyResponse(undefined);
      setIsOpen(false);
      setShippingAddressDraft(address);
      saveDraft(false);
    },
    (error: unknown) => {
      log.warn(getErrorMessage(error));
    },
    queryClient
  );

  useEffect(() => {
    setShippingAddressDraft(proposal.customer?.shippingAddress || {});
  }, [proposal.customer?.shippingAddress]);

  useEffect(() => {
    setCustomerNameDraft(proposal.customer?.name);
  }, [proposal.customer?.name]);

  const saveOrVerify = () => {
    if (isAddressVerificationEnabled) {
      verifyAddress(shippingAddressDraft);
    } else {
      saveDraft();
    }
  };

  const acceptVerifiedAddress = (verifiedAddress: AddressRest) => {
    acceptVerified(verifiedAddress);
  };

  const acceptCurrentAddress = () => {
    setVerifyResponse(undefined);
    saveDraft();
  };

  const editCurrentAddress = () => {
    setVerifyResponse(undefined);
  };

  const handleUseCodeChange = (code: string): void => {
    setTaxDraft((prev) => ({
      ...prev,
      taxUseCode: code as any,
    }));
  };

  const useCodeOptions =
    exemptCodes?.map((c) => {
      const label = tk(`exemptCodes.${c}`);
      return {
        value: c,
        name: `${c} - ${label}`,
      };
    }) || [];

  return (
    <>
      <VerifyAddressModal
        verifyAddressResponse={verifyResponse}
        onAcceptVerified={acceptVerifiedAddress}
        onAcceptCurrent={acceptCurrentAddress}
        onEditCurrent={editCurrentAddress}
      />
      <Modal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        header="Shipping details"
        footer={
          <ButtonBar>
            <Button
              label="Cancel"
              type="secondary"
              onClick={() => setIsOpen(false)}
            />
            <Button
              label={
                isAddressVerificationEnabled
                  ? 'Verify and save'
                  : 'Save ship to'
              }
              onClick={saveOrVerify}
              isDisabled={!areAddressFieldsValid || !hasCustomerName}
              isLoading={isLoading || isVerifying}
            />
          </ButtonBar>
        }
      >
        {hasSigning && (
          <Type paragraph>
            Confirm your company's legal name and shipping address so it appears
            correctly on your signed agreement.
          </Type>
        )}

        <FormField
          label="Legal company name"
          errorToShow={getNameError('name')}
        >
          <Input
            value={customerNameDraft}
            onChange={(value) => setCustomerNameDraft(value)}
          />
        </FormField>
        {((enableTaxId && isCollectTaxIdEnabled) || taxDraft.tin) && (
          <FormField label={tk('Tax ID')}>
            <Input
              id="tin"
              value={taxDraft.tin}
              onChange={(tin) => {
                setTaxDraft((prev) => ({
                  ...prev,
                  tin,
                }));
              }}
            />
          </FormField>
        )}

        {taxyTurby && (
          <>
            <div className="pb-2">
              <ToggleSwitch
                label={tk('Tax exempt')}
                alignLabel="right"
                name="taxExempt"
                value={taxDraft.taxExempt}
                onChange={(checked) => {
                  setTaxDraft((prev) => ({
                    ...prev,
                    taxExempt: checked,
                  }));
                }}
              />
            </div>

            {taxDraft.taxExempt && (
              <div className="grid grid-cols-2 gap-x-4">
                {!enableTaxId && (
                  <FormField label={tk('Tax ID')}>
                    <Input
                      id="tin"
                      value={taxDraft.tin}
                      onChange={(tin) => {
                        setTaxDraft((prev) => ({
                          ...prev,
                          tin,
                        }));
                      }}
                    />
                  </FormField>
                )}
                <FormField label={tk('Tax exempt use code')}>
                  <Select<string>
                    dataTestId="input-use-code"
                    onChange={handleUseCodeChange}
                    value={taxDraft.taxUseCode as string}
                    options={useCodeOptions}
                  />
                </FormField>
              </div>
            )}
          </>
        )}
        <AddressForm
          addressData={shippingAddressDraft}
          onChange={(address) => setShippingAddressDraft(address)}
          getErrorToShow={getErrorToShow}
          countries={countries}
          regions={regions}
        />
      </Modal>
    </>
  );
};

export default ShippingAddressModal;
