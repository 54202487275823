import {
  CustomizeTieredPriceModal,
  isProductTiered,
  Item,
  ItemSchedule,
  PaymentSchedule,
  Proposal,
  ProposalConfig,
  TabsLoader,
} from 'common';
import React, { useEffect, useState } from 'react';
import { SelectedOptionProps } from '../../types/model';
import { ItemCheckoutTab } from './ItemCheckoutTab';
import { ItemDeltasTab } from './ItemDeltasTab';
import styles from './ProductsSection.module.scss';
import { useOrgDefaults } from '../../services/orgConfigs';
import { getIsChangesOnly } from '../../utils/pdf';

type ModalDefaults =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      item: Item;
      selectedSchedule: ItemSchedule;
    };

const CONFIG_KEY = 'proposalConfig';

const priceModalDefaults: ModalDefaults = {
  isOpen: false,
};

interface Props {
  isPdf: boolean;
  isReadOnly: boolean;
  proposal: Proposal;
  selectedOptions?: SelectedOptionProps;
}

const tabs = [
  {
    label: 'All',
    key: 'all',
    component: null,
  },
  {
    label: 'Changes only',
    key: 'changes',
    component: null,
  },
];

export const ProductsSection: React.FC<Props> = ({
  proposal,
  selectedOptions,
  isReadOnly,
  isPdf,
}) => {
  const isChangeProposal = proposal.proposalType === 'change';
  const { data: orgDefaults, isLoading: isOrgDefaultsLoading } =
    useOrgDefaults();
  const isChangesOnly = getIsChangesOnly();

  const [selectedTab, setSelectedTab] = useState<string>(
    isChangesOnly && isChangeProposal ? 'changes' : 'all'
  );

  useEffect(() => {
    if (orgDefaults) {
      if (isChangeProposal) {
        const proposalConfig = orgDefaults.get(CONFIG_KEY)
          ?.configValue as ProposalConfig;
        const changesOnly = proposalConfig.changesOnlyViewAsDefault;
        setSelectedTab(changesOnly ? 'changes' : 'all');
      }
    }
  }, [orgDefaults, isChangeProposal]);

  const billingPeriod: PaymentSchedule['payEvery'] | undefined =
    selectedOptions?.selectedSchedule?.payEvery ||
    proposal.defaultBillingPeriod;

  const [priceModal, setPriceModal] =
    useState<ModalDefaults>(priceModalDefaults);

  const handleShowPriceModalClick = (
    selectedSchedule: ItemSchedule,
    item: Item
  ) => {
    setPriceModal({
      isOpen: true,
      selectedSchedule,
      item,
    });
  };

  if (!billingPeriod) {
    return null;
  }

  return (
    <div className="mt-4 overflow-x-auto">
      {!isPdf && isChangeProposal && (
        <div className={styles.tabSection}>
          <div className={styles.tabs}>
            <TabsLoader
              tabs={tabs}
              defaultActiveTab={selectedTab}
              onChange={setSelectedTab}
              isLoading={isOrgDefaultsLoading}
            />
          </div>
        </div>
      )}
      {selectedTab &&
        (selectedTab === 'all' ? (
          <ItemCheckoutTab
            proposal={proposal}
            isPdf={isPdf}
            isReadOnly={isReadOnly}
            billingPeriod={billingPeriod}
            onShowPriceModalClick={handleShowPriceModalClick}
          />
        ) : (
          <ItemDeltasTab billingPeriod={billingPeriod} proposal={proposal} />
        ))}
      {priceModal.isOpen && isProductTiered(priceModal.item.product) && (
        <CustomizeTieredPriceModal
          selectedItem={priceModal.item}
          isOpen={priceModal.isOpen}
          selectedSchedule={priceModal.selectedSchedule}
          closeModal={() => setPriceModal(priceModalDefaults)}
          isViewOnlyMode
        />
      )}
    </div>
  );
};
