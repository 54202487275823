import { useQueryClient } from '@tanstack/react-query';
import {
  Address,
  AddressDisplay,
  AddressForm,
  areAddressesTheSame,
  Button,
  ButtonBar,
  BuyerUpdateCustomerRequest,
  Checkbox,
  Contact,
  ContactInternal,
  CreateContactRequest,
  Customer,
  emailValidator,
  FormField,
  getCountry,
  getErrorMessage,
  getPersonFullName,
  Icon,
  Input,
  isValidAddress,
  log,
  MutedDiv,
  Proposal,
  useFlags,
  useFormValidation,
} from 'common';
import React, { useEffect, useState } from 'react';
import {
  useCountries,
  useRegion,
  useUpdateCustomerAsBuyer,
} from '../services/proposal';
import { BILL_TO_SECTION } from '../utils/scrolling';
import { useActiveEmail } from '../utils/utils';
import { useIsPreview } from '../utils/viewMode';
import { getIsSectionMuted, hasValidCustomerInfo } from '../utils/workflow';
import MissingShippingAddressButton from './MissingShippingAddressButton';
import ProposalLayout from './ProposalLayout';
import AddBillingContactModal from './Signing/AddBillingContactModal';
import SubTitle from './SubTitle';

interface Props {
  editable?: boolean;
  isBillingInfoBeingEdited: boolean;
  isPdf: boolean;
  proposal: Proposal;
  setIsBillingInfoBeingEdited: (rv: boolean) => void;
}

// filter out source, externalId, id etc...
// - this form is only going to send these three fields,
// the backend does a lookup on email, and then an upsert
const toBuyerContactRequest = (
  contact?: ContactInternal
): CreateContactRequest => {
  return {
    firstName: contact?.firstName || '',
    lastName: contact?.lastName || '',
    email: contact?.email || '',
  };
};

const BillingInfoSection: React.FC<Props> = ({
  proposal,
  editable,
  setIsBillingInfoBeingEdited,
  isBillingInfoBeingEdited,
  isPdf,
}: Props) => {
  const queryClient = useQueryClient();
  const isPreview = useIsPreview();

  const getSavedBillingContactOrActive = (
    savedBillingContact: Contact | undefined,
    activeEmail: string
  ): CreateContactRequest => {
    if (isPreview) {
      const bc: ContactInternal | undefined =
        savedBillingContact ?? proposal.proposalContacts[0]?.contact;

      return toBuyerContactRequest(bc);
    }

    const bc: ContactInternal | undefined = savedBillingContact
      ? savedBillingContact
      : proposal.proposalContacts.find((c) => c.contact?.email === activeEmail)
          ?.contact;

    return toBuyerContactRequest(bc);
  };
  const taxId: string | undefined = proposal.customer?.tin;

  const [isBillingInviteFormOpen, setIsBillingInviteFormOpen] = useState(false);
  const { enableTaxId } = useFlags();
  // Default to the bill to bill address, customer billing address, or customer shipping address
  const addressInfo =
    proposal.billTo?.billingAddress ||
    proposal.customer?.billingAddress ||
    proposal.customer?.shippingAddress;
  const activeEmail = useActiveEmail();
  // If billTo populated on proposal, display bill to contact instead of customer contact
  const savedBillingContactOrActive = getSavedBillingContactOrActive(
    proposal.billTo
      ? proposal.billTo.billingContact
      : proposal.customer?.billingContact,
    activeEmail
  );

  const [billingContactDraft, setBillingContactDraft] =
    useState<CreateContactRequest>(savedBillingContactOrActive);

  useEffect(() => {
    setBillingContactDraft(savedBillingContactOrActive);
  }, [JSON.stringify(savedBillingContactOrActive)]);

  const [editedAddress, setEditedAddress] = useState<Address>(
    addressInfo || {}
  );

  const { data: countries = [] } = useCountries();
  const { data: regions = [] } = useRegion(
    getCountry(editedAddress, countries)
  );

  useEffect(() => setEditedAddress(addressInfo || {}), [addressInfo]);

  const {
    getErrorToShow: getAddressErrorToShow,
    setHasVisitedField: setHasVisitedAddressField,
    isFormValid: areAddressFieldsValid,
    resetUIState: resetAddressUIState,
  } = useFormValidation<Address>(
    [
      {
        fieldName: 'streetAddress',
        isRequired: true,
        // in form the input is labeled as "Address line 1" - since humanReadableName is for
        // error message, "Address is required" sounds better than "Address line 1 is required"
        humanReadableName: 'Address',
      },
      {
        fieldName: 'city',
        isRequired: true,
        humanReadableName: 'City',
      },
      {
        fieldName: 'postalCode',
        isRequired: true,
        humanReadableName: 'Zip/Postal code',
      },
      {
        fieldName: 'country',
        isRequired: true,
        humanReadableName: 'Country',
      },
    ],
    editedAddress
  );

  const {
    getErrorToShow,
    setHasVisitedField,
    isFormValid: areContactFieldsValid,
    resetUIState,
  } = useFormValidation<Contact>(
    [
      {
        fieldName: 'firstName',
        isRequired: true,
        humanReadableName: 'First name',
      },
      {
        fieldName: 'lastName',
        isRequired: true,
        humanReadableName: 'Last name',
      },
      {
        isRequired: true,
        fieldName: 'email',
        validator: emailValidator,
        humanReadableName: 'Email',
      },
    ],
    billingContactDraft
  );

  useEffect(() => {
    setBillingContactDraft(savedBillingContactOrActive);
    setEditedAddress(addressInfo || {});
    resetUIState();
    resetAddressUIState();
  }, [isBillingInfoBeingEdited]);

  const isFormValid = areAddressFieldsValid && areContactFieldsValid;
  const isMissingShippingAddress = !isValidAddress(
    proposal.customer?.shippingAddress
  );
  const isDisabled: boolean = editable === false || isMissingShippingAddress;
  const isSaveDisabled = isDisabled || !isFormValid;

  const closeForm = () => {
    setIsBillingInfoBeingEdited(false);
  };

  const handleSubmit = () => {
    const body: BuyerUpdateCustomerRequest = {
      billingAddress: editedAddress,
      billingContact: billingContactDraft,
      tin: taxId,
    };

    mutate(body);
  };

  const submitIfValid = () => {
    if (isFormValid) {
      handleSubmit();
    }
  };

  const { mutate, isPending: isSaving } = useUpdateCustomerAsBuyer(
    proposal.id,
    activeEmail
  )(
    (successData: Customer) => {
      setIsBillingInfoBeingEdited(false);
      log.debug('MUTATION SUCCESS', successData);
    },
    (error: unknown) => {
      log.warn('MUTATION ERROR', getErrorMessage(error));
    },
    queryClient
  );

  const withHeader = (children: React.ReactNode): React.ReactElement => (
    <>
      <div className="flex justify-center mt-8">
        <MissingShippingAddressButton proposal={proposal} />
      </div>
      <MutedDiv
        isMuted={getIsSectionMuted(proposal, 'billing_info')}
        id={BILL_TO_SECTION}
        className="print:hidden mt-8 break-inside-avoid"
      >
        <SubTitle title="Bill to" />
        {children}
      </MutedDiv>
    </>
  );

  const renderSecondaryBillingButton = () => {
    if (!proposal.customer?.billingContact) {
      return null;
    }

    if (hasValidCustomerInfo(proposal)) {
      return <Button type="secondary" label="Cancel" onClick={closeForm} />;
    }

    return (
      <Button
        type="secondary"
        label="I cannot set up payments"
        onClick={() => setIsBillingInviteFormOpen(true)}
      />
    );
  };

  if (!isBillingInfoBeingEdited) {
    return withHeader(
      <ProposalLayout.TwoColumn noPadding>
        <div>
          <div className="h3 pb-4">Billing contact person</div>
          {proposal.billTo?.name && (
            <div className="flex items-top justify-start gap-1">
              <div className="flex justify-center w-9 shrink-0">
                <Icon.Company />
              </div>
              <div className="font-bold mt-2">{proposal.billTo.name}</div>
            </div>
          )}

          <div className="flex items-top justify-start gap-1">
            <div className="flex justify-center w-9 shrink-0">
              <Icon.UserCircle />
            </div>
            <div className="font-bold mt-2">
              {getPersonFullName(billingContactDraft)}
            </div>
          </div>

          {billingContactDraft.email && (
            <div className="flex items-top justify-start gap-1">
              <div className="flex justify-center w-9 shrink-0">
                <Icon.Email />
              </div>
              <div className="mt-2">{billingContactDraft.email}</div>
            </div>
          )}
        </div>
        <div>
          <div className="pb-4 h3">Billing address</div>
          <div className="flex justify-start items-top gap-1">
            <div className="flex justify-center w-9 shrink-0">
              <Icon.Location />
            </div>
            <div className="mt-2">
              {isPreview && !isValidAddress(editedAddress) ? (
                <div className="text-red print:hidden">
                  Billing address not set
                </div>
              ) : (
                <div>
                  <AddressDisplay address={editedAddress} />
                  {((enableTaxId && taxId) || taxId) && <p>Tax ID: {taxId}</p>}
                </div>
              )}
            </div>
          </div>

          {!isDisabled && (
            <ButtonBar className="print:hidden ml-10">
              <Button
                label="Change"
                dataTestId="change-billing-button"
                type="secondary"
                onClick={() => setIsBillingInfoBeingEdited(true)}
              />
            </ButtonBar>
          )}
        </div>
      </ProposalLayout.TwoColumn>
    );
  }

  if (isPdf) {
    return null;
  }

  const isShippingValid = isValidAddress(proposal.customer?.shippingAddress);

  const areBillingAndShippingTheSameAndValid =
    areAddressesTheSame(proposal.customer?.shippingAddress, editedAddress) &&
    isShippingValid;

  const handleCheckSameAsShipping = (checked: boolean) => {
    if (checked && proposal.customer?.shippingAddress) {
      setEditedAddress(proposal.customer.shippingAddress);
    } else {
      resetAddressUIState();
      setEditedAddress({});
    }
  };

  return withHeader(
    <ProposalLayout.TwoColumn noPadding>
      <div>
        <div className="h3 pb-4">Billing contact person</div>
        <div className="flex gap-4">
          <FormField
            label="First name"
            className="flex-1"
            errorToShow={getErrorToShow('firstName')}
          >
            <Input
              isDisabled={isDisabled}
              value={billingContactDraft.firstName}
              onChange={(value) =>
                setBillingContactDraft((prev) => ({
                  ...prev,
                  firstName: value,
                }))
              }
              dataTestId="co-billing-contact-first-name"
              onBlur={() => setHasVisitedField('firstName')}
              onEnter={submitIfValid}
            />
          </FormField>
          <FormField
            label="Last name"
            className="flex-1"
            errorToShow={getErrorToShow('lastName')}
          >
            <Input
              isDisabled={isDisabled}
              value={billingContactDraft.lastName}
              onChange={(value) =>
                setBillingContactDraft((prev) => ({
                  ...prev,
                  lastName: value,
                }))
              }
              onBlur={() => setHasVisitedField('lastName')}
              onEnter={submitIfValid}
              dataTestId="co-billing-contact-last-name"
            />
          </FormField>
        </div>

        <FormField
          label="Contact email"
          className="w-full"
          infoText="Email which will receive invoices and payment updates"
          errorToShow={getErrorToShow('email')}
        >
          <Input
            isDisabled={isDisabled}
            value={billingContactDraft.email}
            onChange={(value) =>
              setBillingContactDraft((prev) => ({
                ...prev,
                email: value,
              }))
            }
            onEnter={submitIfValid}
            onBlur={() => setHasVisitedField('email')}
            dataTestId="co-billing-contact-last-email"
          />
        </FormField>
      </div>
      <div>
        <div className="pb-4 h3">Billing address</div>
        <div>
          <Checkbox
            name="isBillingSameAsShipping"
            dataTestId="checkbox-isBillingSameAsShipping"
            value={areBillingAndShippingTheSameAndValid}
            isDisabled={!isShippingValid}
            onChange={handleCheckSameAsShipping}
            label="Same as shipping"
          />
        </div>
        <div className="flex-1 pt-4">
          {areBillingAndShippingTheSameAndValid ? (
            <AddressDisplay address={editedAddress} />
          ) : (
            <AddressForm
              isDisabled={isDisabled}
              addressData={editedAddress}
              onChange={(address) => {
                setEditedAddress(address);
              }}
              setHasVisitedField={setHasVisitedAddressField}
              getErrorToShow={getAddressErrorToShow}
              countries={countries}
              regions={regions}
            />
          )}
        </div>

        <ButtonBar>
          {renderSecondaryBillingButton()}

          <Button
            dataTestId="saveBillTo"
            label="Save bill to"
            isDisabled={isSaveDisabled}
            isLoading={isSaving}
            onClick={handleSubmit}
          />
        </ButtonBar>

        {proposal.id && (
          <AddBillingContactModal
            isOpen={isBillingInviteFormOpen}
            onClose={() => setIsBillingInviteFormOpen(false)}
            proposalId={proposal.id}
          />
        )}
      </div>
    </ProposalLayout.TwoColumn>
  );
};

export default BillingInfoSection;
